/**
 * Created by razial on 10.11.2022
 */

cleverapps.overrideStyles(cleverapps.styles.Decorators, {
    "decor_title_right": {
        lovesTitle: "bg_window",
        type: Decors.TYPE_IMAGE,
        image: bundles.windows.frames.window_title_decor,
        scale: {
            x: -1,
            y: 1
        },
        zOrder: -1,
        x: { align: "right", dx: 105 },
        y: { align: "top", dy: 25 }
    },

    "decor_title_left": {
        lovesTitle: "bg_window",
        type: Decors.TYPE_IMAGE,
        image: bundles.windows.frames.window_title_decor,
        scale: {
            x: 1,
            y: 1
        },
        zOrder: -1,
        x: { align: "left", dx: -105 },
        y: { align: "top", dy: 25 }
    },

    "window_notch_top": {
        image: bundles.windows.frames.window_notch_horizontal,
        type: Decors.TYPE_REPEATABLE,
        direction: cleverapps.UI.HORIZONTAL,
        step: 220,
        zOrder: 0,
        x: { align: "left", dx: 120 },
        y: { align: "top", dy: -24 },
        lovesBackgroundAndForeground: true
    },

    "window_notch_bottom": {
        image: bundles.windows.frames.window_notch_horizontal,
        type: Decors.TYPE_REPEATABLE,
        direction: cleverapps.UI.HORIZONTAL,
        step: 220,
        zOrder: 0,
        x: { align: "left", dx: 120 },
        y: { align: "bottom", dy: 33 },
        lovesBackgroundAndForeground: true
    },

    "window_notch_left": {
        image: bundles.windows.frames.window_notch_vertical,
        type: Decors.TYPE_REPEATABLE,
        direction: cleverapps.UI.VERTICAL,
        step: 250,
        zOrder: 0,
        x: { align: "left", dx: 23 },
        y: { align: "top", dy: -140 },
        lovesBackgroundAndForeground: true
    },

    "window_notch_right": {
        image: bundles.windows.frames.window_notch_vertical,
        type: Decors.TYPE_REPEATABLE,
        direction: cleverapps.UI.VERTICAL,
        step: 250,
        zOrder: 0,
        x: { align: "right", dx: -22 },
        y: { align: "top", dy: -140 },
        lovesBackgroundAndForeground: true
    },

    "window_liana_top_left": {
        image: bundles.windows.frames.window_top_liana,
        type: Decors.TYPE_IMAGE,
        zOrder: 0,
        x: { align: "left", dx: 0 },
        y: { align: "top", dy: 36 },
        lovesBackgroundAndForeground: true,
        scale: {
            x: 1,
            y: 1
        }
    },

    "window_liana_top_right": {
        image: bundles.windows.frames.window_top_liana,
        type: Decors.TYPE_IMAGE,
        zOrder: 0,
        x: { align: "right", dx: 0 },
        y: { align: "top", dy: 36 },
        lovesBackgroundAndForeground: true,
        scale: {
            x: -1,
            y: 1
        }
    },

    "window_liana_left": {
        image: bundles.windows.frames.window_side_liana,
        type: Decors.TYPE_IMAGE,
        zOrder: 0,
        x: { align: "left", dx: -31 },
        y: { align: "bottom", dy: 78 },
        lovesBackgroundAndForeground: true,
        scale: {
            x: 1,
            y: 1
        }
    },

    "window_liana_right": {
        image: bundles.windows.frames.window_side_liana,
        type: Decors.TYPE_IMAGE,
        zOrder: 0,
        x: { align: "right", dx: 31 },
        y: { align: "bottom", dy: 78 },
        lovesBackgroundAndForeground: true,
        scale: {
            x: -1,
            y: 1
        }
    },

    "window_liana_bottom_left": {
        image: bundles.windows.frames.window_bottom_liana,
        type: Decors.TYPE_IMAGE,
        zOrder: 0,
        x: { align: "left", dx: 67 },
        y: { align: "bottom", dy: -25 },
        lovesBackgroundAndForeground: true,
        scale: {
            x: 1,
            y: 1
        }
    },

    "window_liana_bottom_right": {
        image: bundles.windows.frames.window_bottom_liana,
        type: Decors.TYPE_IMAGE,
        zOrder: 0,
        x: { align: "right", dx: -67 },
        y: { align: "bottom", dy: -25 },
        lovesBackgroundAndForeground: true,
        scale: {
            x: -1,
            y: 1
        }
    },

    "shop_marquise_coins": {
        image: bundles.tile_shop.frames.marquise_png,
        x: { align: "center" },
        y: { align: "top", dy: 10 },
        zOrder: 2,
        fitToBox: {
            width: 450,
            height: 166,
            maxScale: 2
        }
    },

    "shop_marquise_prem": {
        image: bundles.tile_shop.frames.prem_marquise_png,
        x: { align: "center" },
        y: { align: "top", dy: 12 },
        fitToBox: {
            width: 500,
            height: 166,
            maxScale: 2
        }
    },

    "shop_marquise_lives": {
        image: bundles.tile_shop.frames.marquise_png,
        x: { align: "center" },
        y: { align: "top", dy: 10 },
        zOrder: 2,
        fitToBox: {
            width: 500,
            height: 166,
            maxScale: 2
        }
    },

    "xmas_window_title_right": {
        type: Decors.TYPE_ANIMATION,
        json: bundles.windows.jsons.xmas_window_title,
        animation: "right",
        x: { align: "right", dx: 100 },
        y: { align: "top", dy: 26 },
        lovesTitle: "bg_window"
    },

    "xmas_window_title_left": {
        type: Decors.TYPE_ANIMATION,
        json: bundles.windows.jsons.xmas_window_title,
        animation: "left",
        x: { align: "left", dx: -100 },
        y: { align: "top", dy: 26 },
        lovesTitle: "bg_window"
    },

    "xmas_window_alternative_title_right": {
        type: Decors.TYPE_ANIMATION,
        json: bundles.windows.jsons.xmas_window_title,
        animation: "right",
        x: { align: "right", dx: 55 },
        y: { align: "top", dy: 17 },
        zOrder: -1,
        lovesTitle: "nobg_window"
    },

    "xmas_window_alternative_title_left": {
        type: Decors.TYPE_ANIMATION,
        json: bundles.windows.jsons.xmas_window_title,
        animation: "left",
        x: { align: "left", dx: -55 },
        y: { align: "top", dy: 18 },
        zOrder: -1,
        lovesTitle: "nobg_window"
    },

    "xmas_window_right_top": {
        type: Decors.TYPE_ANIMATION,
        json: bundles.windows.jsons.xmas_window,
        animation: "up_right",
        x: { align: "right", dx: 23 },
        y: { align: "top", dy: 28 },
        lovesBackgroundAndForeground: true
    },

    "xmas_window_right_bottom": {
        type: Decors.TYPE_ANIMATION,
        json: bundles.windows.jsons.xmas_window,
        animation: "down_right",
        x: { align: "right", dx: 21 },
        y: { align: "bottom", dy: -20 },
        lovesBackgroundAndForeground: true
    },

    "xmas_window_left_bottom": {
        type: Decors.TYPE_ANIMATION,
        json: bundles.windows.jsons.xmas_window,
        animation: "down_left",
        x: { align: "left", dx: -21 },
        y: { align: "bottom", dy: -20 },
        lovesBackgroundAndForeground: true
    },

    "xmas_window_left_top": {
        type: Decors.TYPE_ANIMATION,
        json: bundles.windows.jsons.xmas_window,
        animation: "up_left",
        x: { align: "left", dx: -20 },
        y: { align: "top", dy: 28 },
        lovesBackgroundAndForeground: true
    },

    "halloween_window_candle_right": {
        image: bundles.windows.frames.spiderweb_decor,
        type: Decors.TYPE_IMAGE,
        x: { align: "right", dx: -35 },
        y: { align: "top", dy: -37 },
        lovesBackgroundAndForeground: true,
        scale: {
            x: 1,
            y: 1
        }
    },

    "halloween_window_candle_left": {
        image: bundles.windows.frames.candle_decor,
        type: Decors.TYPE_IMAGE,
        x: { align: "left", dx: 70 },
        y: { align: "top", dy: 54 },
        lovesBackgroundAndForeground: true,
        scale: {
            x: 1,
            y: 1
        }
    },

    "halloween_window_lian_left": {
        image: bundles.windows.frames.lian_down_decor,
        type: Decors.TYPE_IMAGE,
        x: { align: "left", dx: -15 },
        y: { align: "center", dy: -30 },
        lovesBackgroundAndForeground: true,
        scale: {
            x: 1,
            y: 1
        }
    },

    "halloween_window_lian_top": {
        image: bundles.windows.frames.lian_left_decor,
        type: Decors.TYPE_IMAGE,
        x: { align: "center", dx: 20 },
        y: { align: "top", dy: 26 },
        lovesBackgroundAndForeground: true,
        scale: {
            x: 1,
            y: 1
        }
    },

    "halloween_window_lian_right": {
        image: bundles.windows.frames.lian_down_decor,
        type: Decors.TYPE_IMAGE,
        x: { align: "right", dx: 10 },
        y: { align: "center", dy: 10 },
        lovesBackgroundAndForeground: true,
        scale: {
            x: 1,
            y: 1
        }
    },

    "halloween_window_lian_bottom": {
        image: bundles.windows.frames.lian_right_decor,
        type: Decors.TYPE_IMAGE,
        x: { align: "left", dx: 460 },
        y: { align: "top", dy: 52 },
        lovesBackgroundAndForeground: true,
        scale: {
            x: 1,
            y: 1
        }
    },

    "halloween_window_decor_bg_right": {
        image: bundles.windows.frames.decor_bg,
        type: Decors.TYPE_IMAGE,
        x: { align: "right", dx: 66 },
        y: { align: "bottom", dy: 170 },
        lovesBackgroundAndForeground: true,
        scale: {
            x: 1,
            y: 1
        }
    },

    "halloween_window_pumpkin": {
        image: bundles.windows.frames.pumpkin_decor,
        type: Decors.TYPE_IMAGE,
        x: { align: "right", dx: -158 },
        y: { align: "top", dy: 40 },
        lovesBackgroundAndForeground: true,
        scale: {
            x: 1,
            y: 1
        }
    },

    "halloween_window_spider": {
        image: bundles.windows.frames.spider_decor,
        type: Decors.TYPE_IMAGE,
        x: { align: "left", dx: 37 },
        y: { align: "bottom", dy: 41 },
        lovesBackgroundAndForeground: true,
        scale: {
            x: 1,
            y: 1
        }
    },

    "leaders_window_cap": {
        image: bundles.leaders_window.frames.window_cup_decor,
        type: Decors.TYPE_IMAGE,
        x: { align: "center", dx: 0 },
        y: { align: "top", dy: 375 },
        lovesBackgroundAndForeground: true,
        scale: {
            x: 1,
            y: 1
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.SceneDecors, {
    xmas_menubar: {
        image: bundles.menubar.frames.xmas_menubar,
        x: { align: "left", dx: 17 },
        y: { align: "top", dy: 28 }
    },

    xmas_menubar_level: {
        image: bundles.menubar.frames.xmas_menubar_level,
        x: { align: "left", dx: 50 },
        y: { align: "top", dy: 12 }
    },

    xmas_control_buttons: {
        image: bundles.controlbuttons.frames.xmas_control_buttons,
        x: { align: "center", dx: 0 },
        y: { align: "top", dy: 11 }
    },

    xmas_sidebar: {
        image: bundles.sidebar.frames.xmas_sidebar,
        x: { align: "center", dx: -3 },
        y: { align: "bottom", dy: -8 }
    },

    xmas_toolbar: [{
        image: bundles.toolbar.frames.xmas_toolbar,
        x: { align: "left", dx: -29 },
        y: { align: "top", dy: 33 }
    }, {
        image: bundles.toolbar.frames.xmas_toolbar,
        x: { align: "right", dx: 29 },
        y: { align: "top", dy: 33 },
        scale: {
            x: -1,
            y: 1
        }
    }]
});
